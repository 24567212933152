<template>
  <!-- Default -->
  <component v-if="!type" :is="linkType" :href="linkType === 'a' ? $props.href : null" :to="linkType === 'router-link' ? { path: $props.href } : null" class="btn mb-4"
     :class="[
         icon ? 'icon-right' : '',
         color ? 'btn-' + color: ''
         ]">
    <span v-html="compiledContent"></span>
    <Icons v-if="icon === true" :type="icon_type" :icon_weight="icon_weight" />
  </component>

  <!-- Button Group -->
  <component :is="linkType" :href="linkType === 'a' ? $props.href : null" :to="linkType === 'router-link' ? { path: $props.href } : null" v-if="type === 'group'" class="btn mx-2 mb-4"
     :class="[
         color ? 'btn-' + color : '',
         icon? 'icon-right' : ''
       ]">
    <span class="button-text mb-9" v-html="compiledContent"></span>
    <Icons v-if="icon === true" :type="icon_type" :weight="icon_weight" classes="mt-auto"/>
  </component>

  <!-- Navigation -->
  <component :is="linkType" :href="linkType === 'a' ? $props.href : null" :to="linkType === 'router-link' ? { path: $props.href } : null" v-if="type === 'navigation'" class="btn btn-navigation icon-right">
    <span v-html="compiledContent"></span>
    <Icons type="angle-right"/>
  </component>

  <!-- Filter -->
  <component :is="linkType" :href="linkType === 'a' ? $props.href : null" :to="linkType === 'router-link' ? { path: $props.href } : null" v-if="type === 'filter'" class="btn btn-filter icon-right mb-2.5">
    <span v-html="compiledContent"></span>
    <Icons v-if="icon === true" classes="mt-auto" :type="icon_type" :weight="icon_weight" />
  </component>
</template>

<script>
import {defineAsyncComponent} from "vue";
import filterService from '@/services/filterService';

const Icons = defineAsyncComponent(() => import('@/components/Icons'));
export default {
  name: "Buttons",
  props: ["href", "type", "content", "icon", "icon_type", "icon_position", "icon_weight", "color", "buttons", "label"],
  data() {
    return {
      linkType: '',
      compiledContent: ''
    }
  },
  components: {
    Icons
  },
  created() {
    if(this.$props.href[0] === '/') {
      this.linkType = 'router-link'
    } else {
      this.linkType = 'a'
    }

    this.compiledContent = filterService.textCompile(this.$props.content, {emoji: false, openLinksInNewWindow: false}).replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
  }
}
</script>

<style scoped lang="scss">

</style>